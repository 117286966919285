@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Definir gris extremadamente oscuro */
    --ultra-dark-gray: #0a0a0a;
    /* Gris casi negro */

    /* Otros colores y estilos */
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;

    --radius: 0.5rem;
  }

  body {
    /* Aplicar el color de fondo */
    @apply bg-ultra-dark-gray text-foreground;
  }
}

@layer utilities {
  .bg-ultra-dark-gray {
    background-color: var(--ultra-dark-gray);
  }

  .heading {
    @apply font-bold text-4xl md:text-5xl text-center;
  }
}